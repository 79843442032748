import rest from '@exchange/libs/utils/rest/src';

const URL = '/futures';

export interface FuturesLeverage {
  account_id: string;
  leverage: number;
}

export default function FuturesResource({ base, accountUrl }) {
  const futuresResourceUrl = `${accountUrl}${URL}`;

  return {
    getLeverage(): Promise<number | null> {
      return rest
        .request({
          url: `${futuresResourceUrl}/leverage`,
          base,
        })
        .then((response: FuturesLeverage) => response.leverage)
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            return null;
          }
          throw error;
        });
    },
  };
}
