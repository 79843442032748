import percentFormatting from '@exchange/helpers/percent-formatting';
import { type WSMarketMessagePayload } from '@exchange/libs/utils/wss/src';
import { wsSportChannelNames, WSIncomingEventTypes, type WSChannelBase, type WSIncomingBasicMessagePayload } from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

export interface MarketCurrency {
  code: string;
  precision: number;
}
/* eslint-disable camelcase */
export interface BEMarketModel {
  id: string;
  type: MarketType;
  state: MarketState;
  base: MarketCurrency;
  quote: MarketCurrency;
  amount_precision: number;
  market_precision: number;
  min_size: string;
  min_price: string;
  max_price: string;
}

export interface BEMarketTicker {
  instrument_code: string;
  sequence: number;
  state: string;
  is_frozen: number;
  quote_volume: string;
  base_volume: string;
  last_price: string;
  best_bid: string;
  best_ask: string;
  price_change: string;
  price_change_percentage: string;
  high: string;
  low: string;
}
/* eslint-enable camelcase */

export enum MarketState {
  ACTIVE = 'ACTIVE',
  IN_MAINTENANCE = 'IN_MAINTENANCE', // create & cancel order is blocked
  SUSPENDED = 'SUSPENDED', // create order is blocked
  CLOSED = 'CLOSED', // create & cancel order is blocked, market will be removed
  NEW = 'NEW', // this is only on the frontend for now for suspended and in_maintenance markets with no price yet
  POST_ONLY = 'POST_ONLY', // market accepts only `post only` limit orders - there is no trading going on
}

export enum MarketType {
  SPOT = 'SPOT',
  PERP = 'PERP',
}

export interface AllDay {
  high: number;
  low: number;
  volume: number;
  priceChange: number;
  priceChangePercentage: number;
  pricePointData: Array<string>;
}

/**
 * those are used for BEST fee calculation
 */
export interface MarketData {
  id: string;
  lastPrice: number;
  quote: MarketCurrency;
  base: MarketCurrency;
}
export type MinimalViableMarketModel = Partial<MarketModel> & MarketData;

export interface MarketDataToCompare {
  amountPrecision: number;
  marketPrecision: number;
  minSize: number;
  state: MarketState;
}
export class MarketModel {
  public static getMarketId(baseCode: string, quoteCode: string, marketType?: MarketType): string {
    return `${baseCode}_${quoteCode}${marketType === MarketType.PERP ? '_P' : ''}`;
  }

  public id: string;

  public name: string;

  public type: MarketType;

  public base: MarketCurrency;

  public quote: MarketCurrency;

  /**
   * The minimum order total (price x amount = total) for orders on this market
   */
  public minSize: number;

  /**
   * The minimum order price for orders on this market
   */
  public minPrice: number;

  /**
   * The maximum order price for orders on this market
   */
  public maxPrice: number;

  /**
   * The Precision used for order prices on this market
   */
  public marketPrecision: number;

  /**
   * The Precision used for order amounts on this market
   */
  public amountPrecision: number;

  /**
   * The Precision used for order total on this market
   */
  public totalPrecision: number;

  public state: MarketState;

  public lastPrice: number;

  public allDay: AllDay;

  public fiatVolume?: number;

  public stateIs(state: MarketState): boolean {
    return this.state === state;
  }

  public getDataToCompare(): MarketDataToCompare {
    return {
      amountPrecision: this.amountPrecision,
      marketPrecision: this.marketPrecision,
      minSize: this.minSize,
      state: this.state,
    };
  }

  public getMarketUiState = () => {
    if (this.state === MarketState.POST_ONLY) {
      if (Number.isNaN(this.lastPrice)) {
        return MarketState.NEW;
      }
    }

    return this.state;
  };

  get priceChangePercentageToDisplay() {
    return percentFormatting(this.allDay.priceChangePercentage);
  }

  constructor(market: BEMarketModel) {
    this.id = market.id;
    this.name = `${market.base.code}/${market.quote.code}`;
    this.type = market.type;
    this.base = {
      code: market.base.code,
      precision: market.base.precision,
    };
    this.quote = {
      code: market.quote.code,
      precision: market.quote.precision,
    };
    this.minSize = Number(market.min_size);
    this.minPrice = Number(market.min_price);
    this.maxPrice = Number(market.max_price);
    this.marketPrecision = market.market_precision;
    this.amountPrecision = market.amount_precision;
    this.totalPrecision = this.id === 'SHIB_EUR' ? 2 : market.market_precision;
    this.state = market.state;
    this.lastPrice = NaN;
    this.allDay = {
      high: NaN,
      low: NaN,
      volume: NaN,
      priceChange: NaN,
      priceChangePercentage: NaN,
      pricePointData: [],
    };
  }
}

export type MarketTickerMessage =
  | WSMarketMessagePayload
  | (WSIncomingBasicMessagePayload & {
      channel_name: typeof wsSportChannelNames.MARKET_TICKER | typeof wsSportChannelNames.SYSTEM;
    });
export interface WSSMarketUpdateMessage {
  channel_name: typeof wsSportChannelNames.SYSTEM;
  subscription: typeof wsSportChannelNames.SYSTEM;
  type: WSIncomingEventTypes.MARKET_UPDATES;
  update: MarketUpdate;
}

export interface MarketUpdate {
  id: string;
  base: MarketCurrency;
  quote: MarketCurrency;
  min_size: string;
  min_price: string;
  max_price: string;
  market_precision: number;
  amount_precision: number;
  state: MarketState;
  type: MarketType;
}

export interface WSSMarketTickerSubscribeChannel extends WSChannelBase {
  name: typeof wsSportChannelNames.MARKET_TICKER;
  price_points_mode: 'INLINE';
  instrument_codes: Array<string>;
}

export interface WSSMarketTickerUpdateMessage {
  channel_name: typeof wsSportChannelNames.MARKET_TICKER;
  type: WSIncomingEventTypes.MARKET_TICKER_UPDATES;
  ticker_updates: Array<WSSMarketTickerUpdate>;
}

export interface WSSMarketTickerUpdate {
  high: string;
  instrument: string;
  last_price: string;
  low: string;
  price_change: string;
  price_change_percentage: string;
  price_points: Array<WSSPricePoint>;
  volume: string;
}

export interface WSSPricePoint {
  time: string;
  price: string;
}
