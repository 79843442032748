import { MarketsChannelTemplate } from '@exchange/libs/utils/wss/src';
import { wsSportChannelNames, WSChannelNames } from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

import { PricetickMessage } from './pricetick-model';

export class PriceticksChannel extends MarketsChannelTemplate<PricetickMessage> {
  protected get channelName(): WSChannelNames {
    return wsSportChannelNames.PRICE_TICKS;
  }
}

export default new PriceticksChannel();
