import ApiKeysResource from './api-keys-resource';
import FuturesResource from './futures-resource';

const ACCOUNT_URL = '/account';

export default function AccountResources({ base }) {
  return {
    ApiKeys: ApiKeysResource({ base, accountUrl: ACCOUNT_URL }),
    Futures: FuturesResource({ base, accountUrl: ACCOUNT_URL }),
  };
}
