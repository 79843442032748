import { addMinutes } from 'date-fns';
import { computed, reactive } from 'vue';

import BigNumber from '@exchange/helpers/bignumber';
import { accountService } from '@exchange/libs/account/service/src';
import { feesService } from '@exchange/libs/fees/service/src';
import { FeeType } from '@exchange/libs/fees/service/src/lib/fee-tiers-model';
import { marketService } from '@exchange/libs/market/service/src';
import { OrderSide, OrderType, TimeInForceType } from '@exchange/libs/order/shared-model/src/lib/order-essentials';
import orderbookService from '@exchange/libs/orderbook/service/src/lib/orderbook.service';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { settingsService } from '@exchange/libs/settings/service/src';
import { SimpleToast, OrderRejectedToast, toastManagerInstance } from '@exchange/libs/toasts/src';
import { serverClientTimeService } from '@exchange/libs/utils/server-client-time/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

export enum SubmitErrors {
  OVERLOAD = 'OVERLOAD',
  SELF_TRADE = 'SELF_TRADE',
  SUSPENDED_TRADING_FOR_ACCOUNT = 'SUSPENDED_TRADING_FOR_ACCOUNT',
}

interface SubmitStatus {
  success: boolean;
  pending: boolean;
  code: string;
}

interface PromoFeeData {
  applyPromo: boolean;
  fees: {
    [FeeType.maker]: number;
    [FeeType.taker]: number;
  };
}

interface PayLoadCommon {
  instrument_code: string;
  side: OrderSide;
  amount: string;
}

interface PayLoadLimit extends PayLoadCommon {
  type: OrderType.LIMIT;
  price: string;
}

interface PayLoadLimitAdvanced extends PayLoadLimit {
  time_in_force: TimeInForceType;
  is_post_only?: boolean;
  expire_after?: string;
}
interface PayLoadStop extends PayLoadCommon {
  type: OrderType.STOP;
  price: string;
  trigger_price: string;
}

interface OrderFormData {
  orderFormModalOpen: boolean;
  type: OrderType;
  side: OrderSide;
  amount: string | null;
  limitPrice: string | null;
  stopPrice: string | null;
  total: string | null;
  submitStatus: SubmitStatus;
}

export const getSlippageMultiplier = (percent: number, isBuy: boolean) => {
  let multiplier = 100 - percent;

  if (isBuy) {
    multiplier = 100 + percent;
  }

  return new BigNumber(multiplier).div(100);
};
export const getPriceWithSlippage = ({
  minPrice,
  maxPrice,
  basePrice,
  slippageMultiplier,
  precision,
}: {
  minPrice: string;
  maxPrice: string;
  basePrice: string | number;
  slippageMultiplier: BigNumber;
  precision: number;
}) => {
  const minPriceBn = new BigNumber(minPrice);
  const maxPriceBn = new BigNumber(maxPrice);
  let resultingPrice = new BigNumber(basePrice).times(slippageMultiplier);

  if (resultingPrice.lt(minPriceBn)) {
    resultingPrice = minPriceBn;
  }

  if (resultingPrice.gt(maxPriceBn)) {
    resultingPrice = maxPriceBn;
  }

  return resultingPrice.toFixed(precision);
};

class OrderForm {
  private getDefaultSubmitStatus = () => ({
    success: false,
    pending: false,
    code: '',
  });

  public data = reactive<OrderFormData>({
    orderFormModalOpen: false,
    type: OrderType.LIMIT,
    side: OrderSide.BUY,
    amount: null,
    limitPrice: null,
    stopPrice: null,
    total: null,
    submitStatus: this.getDefaultSubmitStatus(),
  });

  private promoFeeData = reactive<PromoFeeData>({
    applyPromo: false,
    fees: {
      [FeeType.maker]: 0,
      [FeeType.taker]: 0,
    },
  });

  private getMarket = () => {
    const marketId = marketService.selectedMarketIdSpot.value;

    return marketService.getMarket(marketId);
  };

  private getMarketPrecisions = () => marketService.getMarketPrecision(this.getMarket());

  public orderFormModalOpen = computed(() => this.data.orderFormModalOpen);

  public setOrderFormModalOpen = (value: boolean) => {
    this.data.orderFormModalOpen = value;
  };

  public setPromoFeeData(promoFeeData: PromoFeeData) {
    this.promoFeeData.applyPromo = promoFeeData.applyPromo;
    this.promoFeeData.fees = promoFeeData.fees;
  }

  public feeValue = computed(() => {
    const { accountCurrentFee } = feesService;
    const { orderAdvanced } = settingsService.settings.userSettings;

    if (!accountCurrentFee.value) {
      return undefined;
    }

    if (this.data.type === OrderType.LIMIT) {
      if (
        orderAdvanced.enabled &&
        orderAdvanced.postOnly &&
        (orderAdvanced.timeInForce === TimeInForceType.GOOD_TILL_CANCELLED || orderAdvanced.timeInForce === TimeInForceType.GOOD_TILL_TIME)
      ) {
        return accountCurrentFee.value.makerFeeSpot;
      }
    }

    return accountCurrentFee.value.takerFeeSpot;
  });

  public postOrder = {
    rest: (pl) => PublicRest.Account.Orders.create(pl),
  };

  public getPostOrderPayload = async ({
    instrument,
    amount,
    limitPrice,
    stopPrice,
    minPrice,
    maxPrice,
  }: {
    instrument: string;
    amount: string | null;
    limitPrice: string | null;
    stopPrice: string | null;
    minPrice: string;
    maxPrice: string;
  }) => {
    if (!amount) {
      return Promise.reject(new Error('no amount'));
    }

    const marketPrecision = this.getMarketPrecisions();

    const pl: PayLoadCommon = {
      instrument_code: instrument,
      amount,
      side: this.data.side,
    };

    switch (this.data.type) {
      case OrderType.MARKET: {
        const getBasePrice = async () => {
          const orderbook = await orderbookService.getOrderbook({ id: pl.instrument_code, precision: marketPrecision.market }).catch(() => ({ value: undefined }));

          const bestAsk = orderbook.value?.bestAsk?.price;
          const bestBid = orderbook.value?.bestBid?.price;

          return (pl.side === OrderSide.BUY ? bestAsk : bestBid) ?? this.data.limitPrice ?? 0;
        };

        const basePrice = await getBasePrice();
        const slippageMultiplier = getSlippageMultiplier(settingsService.settings.userSettings.marketOrdersSlippage, pl.side === OrderSide.BUY);
        const precision = marketPrecision.total;
        const price = getPriceWithSlippage({
          minPrice,
          maxPrice,
          basePrice,
          slippageMultiplier,
          precision,
        });

        const payload: PayLoadLimitAdvanced = {
          ...pl,
          type: OrderType.LIMIT,
          price,
          time_in_force: TimeInForceType.IMMEDIATE_OR_CANCELLED,
        };

        return payload;
      }

      case OrderType.LIMIT: {
        if (!limitPrice) {
          throw new Error('no limit price');
        }

        const payload: PayLoadLimit = {
          ...pl,
          type: OrderType.LIMIT,
          price: limitPrice,
        };

        const { enabled: advancedOn, timeInForce, postOnly, cancelAfter } = settingsService.settings.userSettings.orderAdvanced;

        if (!advancedOn) {
          return payload;
        }

        if (!timeInForce) {
          throw new Error('timeInForce type is undefined');
        }

        const payloadAdvanced: PayLoadLimitAdvanced = {
          ...payload,
          time_in_force: timeInForce,
        };

        if (timeInForce === TimeInForceType.GOOD_TILL_CANCELLED || timeInForce === TimeInForceType.GOOD_TILL_TIME) {
          payloadAdvanced.is_post_only = postOnly || false;
        }

        if (timeInForce === TimeInForceType.GOOD_TILL_TIME && cancelAfter) {
          const getReputedlyServerTime = await serverClientTimeService.getReputedlyServerTime();

          payloadAdvanced.expire_after = addMinutes(getReputedlyServerTime, cancelAfter).toISOString();
        }

        return payloadAdvanced;
      }

      case OrderType.STOP: {
        if (!limitPrice) {
          throw new Error('no limit price');
        }

        if (!stopPrice) {
          throw new Error('no stop price');
        }

        const s: PayLoadStop = {
          ...pl,
          type: OrderType.STOP,
          price: limitPrice,
          trigger_price: stopPrice,
        };

        return s;
      }
      default:
        throw new Error(`no such type ${this.data.type}`);
    }
  };

  public setSubmitStatus({ submitData, reset }: { submitData: SubmitStatus; reset?: boolean } | { submitData?: SubmitStatus; reset: boolean }) {
    let data;

    if (reset) {
      data = this.getDefaultSubmitStatus();
    } else {
      data = submitData;
    }

    this.data.submitStatus = data;
  }

  public setType(value: OrderType) {
    this.data.type = value;
  }

  public setSide(value: OrderSide | 'ASKS' | 'BIDS') {
    const sideMapping = {
      BUY: OrderSide.BUY,
      SELL: OrderSide.SELL,
      ASKS: OrderSide.BUY,
      BIDS: OrderSide.SELL,
    };

    this.data.side = sideMapping[value.toUpperCase()];
  }

  private prefillPrice = (prefillPrice: boolean) => {
    if (!prefillPrice) {
      this.data.limitPrice = null;
      this.data.stopPrice = null;

      return;
    }

    const { lastPrice, marketPrecision } = this.getMarket() || { lastPrice: undefined, marketPrecision: undefined };

    if (lastPrice !== undefined && marketPrecision !== undefined) {
      const price = lastPrice.toFixed(marketPrecision);

      this.data.limitPrice = price;
      this.data.stopPrice = price;
    } else {
      this.data.limitPrice = null;
      this.data.stopPrice = null;
    }
  };

  public softReset() {
    this.data.amount = null;
    this.data.total = null;
    this.setSubmitStatus({ reset: true });
  }

  public hardReset({ prefillPriceUsingCurrentMarket } = { prefillPriceUsingCurrentMarket: false }) {
    this.softReset();
    this.prefillPrice(prefillPriceUsingCurrentMarket);
  }

  private getLimitPriceForCalculations() {
    if (this.data.type === OrderType.MARKET) {
      return new BigNumber(this.getMarket()?.lastPrice || 0);
    }

    if (this.data.limitPrice) {
      return new BigNumber(this.data.limitPrice);
    }

    return null;
  }

  public setAmount(value: string | null) {
    if (value === null) {
      this.data.amount = null;
      this.data.total = null;

      return;
    }

    const limitPrice = this.getLimitPriceForCalculations();

    this.data.amount = value;

    if (limitPrice && !limitPrice.eq(0)) {
      const marketPrecision = this.getMarketPrecisions();
      const precision = marketPrecision.total;

      this.data.total = new BigNumber(value).times(limitPrice).toFixed(precision);
    }
  }

  public setLimitPrice(value: string | null) {
    if (value === null) {
      this.data.limitPrice = null;
      this.data.total = null;

      return;
    }

    this.data.limitPrice = value;

    if (Number(value) === 0) {
      return;
    }

    const marketPrecision = this.getMarketPrecisions();

    if (this.data.amount) {
      const precision = marketPrecision.total;

      this.data.total = new BigNumber(value).times(this.data.amount).toFixed(precision);
    } else if (this.data.total) {
      const precision = marketPrecision.amount;

      this.data.amount = new BigNumber(this.data.total).div(value).toFixed(precision);
    }
  }

  public setStopPrice(value: string | null) {
    this.data.stopPrice = value;
  }

  public setTotal = (value: string | null) => {
    if (value === null) {
      this.data.amount = null;
      this.data.total = null;

      return;
    }

    const limitPrice = this.getLimitPriceForCalculations();

    this.data.total = value;

    if (Number(value) === 0) {
      const zero = '0';

      this.data.amount = zero;
      this.data.total = zero;
    } else if (limitPrice && !limitPrice.eq(0)) {
      const marketPrecision = this.getMarketPrecisions();
      const amountPrecision = marketPrecision.amount;
      const amount = new BigNumber(value).div(limitPrice);

      this.data.amount = amount.toFixed(amountPrecision);
    }
  };

  public showToastOnFailure = () => settingsService.settings.userSettings.orderNotifications.all && settingsService.settings.userSettings.orderNotifications.failed;

  public handleOrderCreatedSuccess = () => {
    this.setSubmitStatus({
      submitData: {
        success: true,
        pending: false,
        code: '',
      },
    });

    this.softReset();
  };

  public handleOrderCreationFailed = (submissionError: string) => {
    if (submissionError === SubmitErrors.OVERLOAD) {
      if (this.showToastOnFailure()) {
        toastManagerInstance.addToast({
          content: SimpleToast,
          props: {
            variant: 'failed',
            title: 'modules.orderForm.toasts.overload.title',
            message: 'modules.orderForm.toasts.overload.message',
          },
        });
      }

      this.setSubmitStatus({
        submitData: {
          pending: false,
          success: false,
          code: '',
        },
      });
    } else if (submissionError === SubmitErrors.SUSPENDED_TRADING_FOR_ACCOUNT && !accountService.userIsVerified.value) {
      this.setSubmitStatus({
        submitData: {
          pending: false,
          success: false,
          code: 'VERIFICATION_NEEDED',
        },
      });
    } else if (submissionError === SubmitErrors.SELF_TRADE) {
      if (this.showToastOnFailure()) {
        toastManagerInstance.addToast({
          content: OrderRejectedToast,
          props: {
            event: {
              reason: submissionError,
            },
          },
        });
      }

      this.setSubmitStatus({
        submitData: {
          pending: false,
          success: false,
          code: 'SELF_TRADE',
        },
      });
    } else {
      logger.error('Order submission failed', submissionError);
      this.setSubmitStatus({
        submitData: {
          pending: false,
          success: false,
          code: submissionError,
        },
      });
      toastManagerInstance.addToast({
        content: OrderRejectedToast,
        props: {
          event: {
            reason: submissionError,
          },
        },
      });
    }
  };
}

const orderFormService = new OrderForm();

export default orderFormService;
